import { Badge, Button } from "@chakra-ui/react";
import LogoSection from "../../components/Logo";
import { FiUpload } from 'react-icons/fi';
{/* <div className="w-150 h-210 items-center justify-center overflow-hidden">
            <img src={`./images/${imgName}.png`} alt="fifa" className="rounded-xl object-fill max-w-full " />
        </div> */}
const ImageComponent = ({ imgName }) => {
    return (
        <div>
            <div class="w-[150px] h-[210px] p-2 flex items-center justify-center">
                <img src={`./images/${imgName}.png`} alt="Image 1" className="w-full h-full rounded-xl object-fill" />
            </div>
        </div>
    )
}

function Tournament() {
    return (
        <div className="flex px-4 pb-4 pl-4 pt-11 flex-col items-start gap-8 flex-1">
            <div className="md:hidden self-center"><LogoSection logowidth={103} logoheight={60} /></div>
            <div className="flex flex-col gap-8">
                <div className="flex md:flex-row flex-col gap-2">
                    <p className="font-Monopol md:order-1 order-2 md:text-5xl text-4xl font-semibold text-white">UPCOMING TOURNAMENT ALERT!</p>
                    <Badge className="md:order-2 order-1 md:self-center self-start" borderRadius={'16px'} display={'flex'} padding={'4px 12px'} color={'var(--orange-500, #EF6820)'} background={'rgba(255, 98, 0, 0.30)'} fontSize={'14px'}>
                        Hot 🔥
                    </Badge>
                </div>
                <div className="flex flex-col gap-4 items-start">
                    <img src="./images/fifa.png" alt="fifa" className="rounded-3xl md:w-full " />
                    <div className="flex flex-col gap-6 items-start">
                        <div className="flex flex-col gap-2 items-start">
                            <p className="font-Monopol text-2xl text-white">FIFA 2023</p>
                            <p className="font-Monopol text-[18px] text-gray-300">Get ready to unleash your skills in our next grand event. With significant prizes and unlimited excitement, every game is a chance to prove your mettle. Stay tuned for details and be part of the next gaming revolution!"</p>
                        </div>
                        <div className="flex md:flex-row flex-col items-start gap-3">
                            <button className="bg-gradient-to-r from-red-500 to-purple-500 hover:to-purple-600 text-white py-[10px] px-[18px] md:w-auto w-full rounded-lg shadow-md">
                                Enlist for Tournament
                            </button>
                            <button className="bg-white text-gray-700 py-[10px] px-[18px] md:w-auto w-full  rounded-lg shadow-md">
                                View Tournament Brackets
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:gap-6 gap:4 items-start w-full">
                <p className="font-Monopol text-2xl text-white">Enlisted Tournaments</p>
                <div className="flex flex-row gap-3 overflow-auto self-stretch">
                    <ImageComponent imgName="fifa1" />
                    <ImageComponent imgName="streetfighter" />
                    <ImageComponent imgName="fortnite" />
                    <ImageComponent imgName="warzone" />
                    <ImageComponent imgName="mariokart" />
                </div>
            </div>
        </div>
    )
}

export default Tournament;