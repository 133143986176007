import { Input, Divider, Box, Text, IconButton } from "@chakra-ui/react";
import { FiUploadCloud } from 'react-icons/fi';
import { useState } from "react";
import LogoSection from "../../components/Logo";

const InputwithLabel = ({ label, placeholder, type }) => {
    return (
        <div className="flex flex-col gap-[6px] md:w-1/2">
            <label className="text-sm text-white">{label}</label>
            <Input
                className="text-white bg-white/10"
                placeholder={placeholder}
            />
        </div>
    );
};

function Profile() {
    const [files, setFiles] = useState([]);
    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
        console.log(event.target.files);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
    };

    return (
        <div className="flex flex-col gap-8 pt-11 pr-4 pb-4 pl-[18px]">
            <div className="md:hidden self-center"><LogoSection logowidth={103} logoheight={60} /></div>
            <p className="text-white font-bold text-2xl">Personal Info</p>
            <div className="bg-gray-900 flex flex-col rounded-lg w-full">
                <div className="flex flex-col p-6 gap-6">
                    <div className="flex flex-col items-center self-stretch gap-5">
                        <img
                            src={!files[0] ? './images/Avatar.png' : URL.createObjectURL(files[0])}
                            alt="avatar"
                            width="126px"
                            height="126px"
                            className="object-cover justify-center"
                        />
                        <div className="flex items-center py-4 px-6 rounded-xl md:w-1/3">
                            <label
                                htmlFor="dropzone-file"
                                className="inline-flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-700 "
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    pt="3"
                                    pb="4"
                                >
                                    <IconButton
                                        py="2"
                                        px="4"
                                        rounded="md"
                                        icon={<FiUploadCloud color="white" />}
                                        bg={"#9E77ED"}
                                        color="white"
                                        onClick={handleUpload}
                                    />
                                    <Text mb="1" fontSize="sm" color="gray.500" fontWeight="semibold">
                                        <span style={{ color: '#9E77ED' }}>Click to upload</span> or drag and drop
                                    </Text>
                                    <Text fontSize="xs" color="gray.500">
                                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </Text>
                                </Box>
                                <Input
                                    id="dropzone-file"
                                    type="file"
                                    display="none"
                                    accept=".jpg, .png, .jpeg, .gif, .svg, .bmp"
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-6 justify-between w-full">
                        <InputwithLabel label={"First Name"} placeholder={"First Name"} />
                        <InputwithLabel label={"Last Name"} placeholder={"Last Name"} />
                    </div>
                    <div className="flex md:flex-row flex-col gap-6 justify-between w-full">
                        <InputwithLabel label={"Username"} placeholder={"Username"} />
                        <InputwithLabel label={"Email address"} placeholder={"example.com"} />
                    </div>
                </div>
                <Divider className="bg-gray-500" />
                <div className="flex self-end gap-3 py-4 px-6">
                    <button className="bg-white text-gray-700 py-[10px] px-[18px] rounded-lg shadow-md">
                        Cancel
                    </button>
                    <button className="bg-gradient-to-r from-red-500 to-purple-500 hover:to-purple-600 text-white py-[10px] px-[18px] rounded-lg shadow-md">
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Profile;