import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogoSection from "../../components/Logo";
import { IconButton, Badge, Divider } from "@chakra-ui/react";
import { FiArrowLeft, FiArrowRight, FiEye, FiGlobe, FiClock, FiLogIn } from 'react-icons/fi';
import { Gamepad } from '@styled-icons/fa-solid/Gamepad';

const LargeLogo = () => {
    return (
        <div className="relative md:w-[772.5px] md:h-[450px] w-[347.625px] h-[202.5px]">
            <img src="./images/logomark.png" className="absolute md:pr-[374px] md:max-h-[450px] pr-[168.3px] max-h-[202.5px] object-cover" alt="logomark" />
            <img src="./images/logotype.png" className="absolute md:pt-[125px] md:pb-[117px] md:pl-[87px] md:max-w-[772.5px] pt-[56.18px] pb-[52.5px] pl-[39.16px] max-w-[347.625px] object-cover" alt="logotype" />
        </div>
    )
}

const ImageComponent = ({ imgName }) => {
    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <img src={`./images/${imgName}.png`} alt="fifa" style={{ borderRadius: '16px', objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }} />
        </div>
    )
}
const BadgeGroup = () => {
    return (
        <div style={{ display: 'flex', gap: '10px', bottom: '0', alignItems: 'end' }}>
            <Badge backgroundColor={'#004EEB'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiEye size={'16px'} /> 15K</div>
            </Badge>
            <Badge backgroundColor={'#6927DA'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiGlobe size={'16px'} /> English</div>
            </Badge>
            <Badge backgroundColor={'#F79009'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiClock size={'16px'} /> 15min ago</div>
            </Badge>
        </div>
    )
}
const LiveMatchComponent = () => {
    return (
        <div className="min-w-[320px]">
            <ImageComponent imgName={'video-fortnite'} />
            <div style={{ display: 'flex', gap: '10px', marginTop: '24px' }}>
                <img src="./images/Avatar.png" alt="avatar" style={{ objectFit: 'cover', justifyContent: 'center', borderRadius: '50%', width: '64px', height: '64px' }} />
                <div>
                    <p style={{ color: 'white', fontSize: '18px' }}>FIFA 2023</p>
                    <p style={{ color: '#D0D5DD', fontSize: '14px' }}>David Beckham</p>
                    <BadgeGroup />
                </div>
            </div>
        </div>
    )
}

const PastMatchComponent = () => {
    return (
        <div className="min-w-[240px]">
            <ImageComponent imgName={'video-fortnite'} />
            <div style={{ display: 'flex', gap: '10px', marginTop: '24px' }}>
                <img src="./images/Avatar.png" alt="avatar" style={{ objectFit: 'cover', justifyContent: 'center', borderRadius: '50%', width: '40px', height: '40px' }} />
                <div>
                    <p style={{ color: 'white', fontSize: '18px' }}>FIFA 2023</p>
                    <p style={{ color: '#D0D5DD', fontSize: '14px' }}>David Beckham</p>
                </div>
            </div>
        </div>
    )
}
const MenuSection = styled.div`
    display: flex;
    padding: 12px 32px;
    justify-content: space-between;
    align-items: center;
    gap: 32px
`
const FirstSection = styled.div`
    background-image: ${`url('./images/background.gif')`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const Textstyle = styled.p`
    color: var(--gray-50, #F9FAFB);
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
`

function Homepage() {
    const navigate = useNavigate();
    const login = () => {
        console.log('login')
        navigate('/live')
    }
    return (
        <div style={{ backgroundColor: 'black' }}>
            <FirstSection>
                <div className="flex md:py-6 md:px-20 py-3 pr-2 pl-4 justify-between">
                    <LogoSection logowidth={103} logoheight={60} />
                    {/* <div className="py-3 px-8 hidden md:flex">
                        <button onClick={() => navigate('/live')}><Textstyle>Home</Textstyle></button>
                        <button onClick={() => navigate('/live')}><Textstyle>Live Betting</Textstyle></button>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <button onClick={() => navigate('/tournament')}><Textstyle>Tournament</Textstyle></button>
                            <Badge borderRadius={'16px'} alignItems={'center'} alignSelf={'center'} padding={'2px 8px'} background={'rgba(255, 98, 0, 0.30)'}>Hot 🔥</Badge>
                        </div>
                    </div> */}
                    <button onClick={() => login()} className="md:flex md:bg-gradient-to-r md:from-red-500 md:to-purple-500 md:hover:to-purple-600 text-white md:py-[10px] md:px-[18px] md:rounded-lg rounded-full md:shadow-md md:font-bold">
                        <p className="md:flex hidden self-center">Connect Wallet</p>
                        <p className="md:hidden flex pr-4 font-extrabold"><FiLogIn/></p>
                    </button>
                </div>
                <div className="flex flex-col gap-6 md:px-28 px-4 md:items-center">
                    <LargeLogo />
                    <p className="text-white text-32px">Where Classic Gaming Meets Modern Rewards!</p>
                </div>
            </FirstSection>
            <div className="flex flex-col md:py-24 md:px-20 py-8 px-4 gap-8">
                <div className="flex flex-col md:gap-5 gap-3">
                    <div className="flex md:flex-row flex-col gap-2">
                        <p className="font-Monopol md:order-1 order-2 md:text-5xl text-4xl font-semibold text-white">UPCOMING TOURNAMENT ALERT!</p>
                        <Badge className="md:order-2 order-1 md:self-center self-start" borderRadius={'16px'} display={'flex'} padding={'4px 12px'} color={'var(--orange-500, #EF6820)'} background={'rgba(255, 98, 0, 0.30)'} fontSize={'14px'}>
                            Hot 🔥
                        </Badge>
                    </div>
                    <p className="font-Monopol md:text-lg text-base font-[400px] text-white">Get ready to unleash your skills in our next grand event. With significant prizes and unlimited excitement, every game is a chance to prove your mettle. Stay tuned for details and be part of the next gaming revolution!"</p>
                </div>
                <img src="./images/fifa.png" alt="fifa" style={{ borderRadius: '10px', width: '100%' }} />
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 md:py-24 md:px-20 py-8 px-4 md:gap-16 gap-8">
                <div className="flex flex-col items-start md:gap-10 gap-3 flex-1">
                    <div className="text-white md:text-8xl text-5xl font-semibold"> Choose Your Champion - Place Your Bet!</div>
                    <p className="self-stretch text-gray-300 md:text-lg text-base font-[400px] leading-7 ">Welcome to the betting arena of Arcade CA$HINO. Here, you decide the winners, place your bets on your favorite players, and revel in the triumph of victory. Your game, your rules</p>
                </div>
                <img src="./images/video-mega.png" alt="logomark" className="rounded-[10px]" />
            </div>
            <div className="flex flex-col gap-16 md:px-28 md:pb-24 py-8 px-4">
                <div className="flex flex-col md:gap-5 gap-3 md:w-[60%] sm:w-full self-start">
                    <p className="text-white md:text-5xl text-4xl font-semibold">How It Works</p>
                    <p className="text-gray-300 md:text-lg text-base">Pick the player you believe in and bet your $CASH. Witness the Win: Watch the match and cheer for your champion. Claim Your Rewards: Winners enjoy their well-deserved payout!</p>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-1 self-center gap-3 px-3">
                    <img src="./images/how1.svg" alt="logomark" />
                    <img src="./images/how2.svg" alt="logomark" />
                    <img src="./images/how2.svg" alt="logomark" />
                </div>
                <div className="flex flex-col gap-6">
                    <div className="flex justify-between">
                        <div className="flex items-center gap-[5px]">
                            <div className="rounded-full w-[8px] h-[8px] bg-red-500"></div>
                            <p className="text-white font-bold text-base">LIVE MATCHES</p>
                        </div>
                        <div className="hidden md:flex gap-[6px]">
                            <button className="bg-black text-white py-[7px] px-[13px]"><FiArrowLeft /></button>
                            <button className="bg-black text-white py-[7px] px-[13px]"><FiArrowRight /></button>
                        </div>
                    </div>
                    <div className="flex gap-4 justify-between md:overflow-hidden overflow-auto">
                        <LiveMatchComponent />
                        <LiveMatchComponent />
                        <LiveMatchComponent />
                        <LiveMatchComponent />
                    </div>
                </div>
                <div>
                    <div className="flex justify-between">
                        <div className="flex items-center gap-3">
                            <Gamepad className="text-white" size={24} />
                            <p className="text-white text-16px">PAST MATCHES</p>
                            <button><p className="text-14px text-purple-500">View All</p></button>
                        </div>
                        <div className="hidden md:flex">
                            <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowLeft />} />
                            <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowRight />} />
                        </div>
                    </div>
                    <div className="flex gap-16 md:justify-between md:overflow-hidden  overflow-auto">
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                    </div>
                </div>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 md:py-6 md:px-20 py-8 px-4">
                <div className="flex flex-col md:gap-10 gap-9 justify-center">
                    <div className="flex flex-col gap-3">
                        <p className="text-purple-500 text-base">About us</p>
                        <p className="text-white md:text-5xl text-4xl">CLASSIC GAMING MEETS MODERN REWARDS</p>
                    </div>
                    <p className="text-gray-300 md:text-lg text-base">Welcome to CA$HINO, a universe where gaming transcends boundaries. We're combining the nostalgic euphoria of arcade games with contemporary esports and integrating it all with a cutting-edge web3 betting platform. Participants can dive into various tournaments, face off in Player vs Player battles, bet on their favorite competitors, or strive for high scores in arcade classics and modern hits alike."</p>
                </div>
                <img
                    src="./images/about1.png"
                    alt="about"
                    className="rounded-10 md:w-1/2 w-full"
                />
            </div>
            <div className="flex flex-col py-12 gap-6">
                <div className="grid md:grid-cols-2 grid-cols-1 md:px-28 px-4 md:justify-between gap-8 w-full">
                    <LogoSection logowidth={103} logoheight={60} />
                    {/* <MenuSection>
                        <button onClick={() => navigate('/live')}><Textstyle>Home</Textstyle></button>
                        <button onClick={() => navigate('/live')}><Textstyle>Live Betting</Textstyle></button>
                        <div className="flex gap-12">
                            <button onClick={() => navigate('/tournament')}><Textstyle>Tournament</Textstyle></button>
                            <Badge borderRadius={'16px'} padding={'2px 8px'} background={'rgba(255, 98, 0, 0.30)'}>Hot 🔥</Badge>
                        </div>
                    </MenuSection> */}
                </div>
                <div className="flex flex-col md:gap-0 gap-6">
                    <Divider />
                    <div className="flex md:justify-between justify-center md:pt-8 md:px-28 md:gap-8 w-full">
                        <p className="text-gray-300 md:justify-start justify-center">© 2077 Arcade CA$HINO. All rights reserved.</p>
                        <div className="hidden md:flex gap-3">
                            <p className="text-gray-300 text-base">Terms</p>
                            <p className="text-gray-300 text-base">Privacy</p>
                            <p className="text-gray-300 text-base">Cookies</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage;